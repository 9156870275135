module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.9.0_gatsby@5.9.1_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"博彩策略研究院","short_name":"博彩策略","description":"了解博彩的合理策略，探究赌博的必胜技巧，人工智能博彩策略，跟踪博彩行业最新资讯，网上娱乐平台推荐，机器学习，大数据分析，百家乐的玩法，彩票的规则，老虎机的数学，尽在博彩研究院。","start_url":"/","background_color":"#ffe6e3","theme_color":"#dd4b39","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"da58723a55b4e3a521c3f447dfb89d26"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-graph@0.2.6/node_modules/gatsby-remark-graph/gatsby-browser.js'),
      options: {"plugins":[],"language":"mermaid","theme":"default"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.9.0_gatsby-plugin-sharp@5.9.0_gatsby@5.9.1/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":672,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@6.9.0_gatsby@5.9.1_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.9.1_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-8B9M1HSRZM"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.9.0_gatsby@5.9.1_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PH5TPW5","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.9.0_gatsby@5.9.1/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#c62828"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.9.0_gatsby@5.9.1/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.9.0_gatsby@5.9.1_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.9.1_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.7.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
